var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_${_vm.key}` }, [
    _c("div", { staticClass: "kt-subheader kt-grid__item" }, [
      _c("div", { staticClass: "kt-container kt-container--fluid" }, [
        _c("div", { staticClass: "kt-subheader__main" }, [
          _c(
            "h3",
            { staticClass: "kt-subheader__title text-nowrap text-truncate" },
            [
              _c("SVGIcon", {
                staticClass: "mt-2 mr-3",
                attrs: { "hex-color": "#0f88ef", name: "communication" },
              }),
              _vm._v(" Communication "),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "kt-subheader__toolbar" }, [
          _c("div", { staticClass: "kt-subheader__wrapper mt-1" }, [
            _vm.$_userMixins_isSchoolUser
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-label-primary btn-icon btn-bold",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.toggleCreateMessage.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-pencil-alt" })]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xl-4" },
            [
              _c("RecentMessages", {
                attrs: { messages: _vm.formattedMessages },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xl-8" },
            [
              _c("MessageInbox", {
                attrs: {
                  messages: _vm.filteredMessages,
                  "change-filter": _vm.changeFilter,
                  "show-deleted": _vm.showDeleted,
                  "toggle-show-deleted": _vm.messages.length
                    ? _vm.toggleShowDeleted
                    : null,
                },
              }),
            ],
            1
          ),
        ]),
        _vm.isLocalDev
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xl-5" }, [
                _c(
                  "div",
                  { staticClass: "kt-portlet kt-portlet--height-fluid" },
                  [_c("ChatGroups")],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-xl-7" }, [_c("Chat")], 1),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }