<template>
<div :key="`key_${key}`">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title text-nowrap text-truncate">
                    <SVGIcon
                        hex-color="#0f88ef"
                        class="mt-2 mr-3"
                        name="communication"
                    />
                    Communication
                </h3>
            </div>
            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper mt-1">
                    <button
                        v-if="$_userMixins_isSchoolUser"
                        type="button"
                        class="btn btn-label-primary btn-icon btn-bold"
                        @click.stop.prevent="toggleCreateMessage"
                    >
                        <i class="fa fa-pencil-alt" />
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-xl-4">
                <RecentMessages
                    :messages="formattedMessages"
                />
            </div>

            <div class="col-xl-8">
                <MessageInbox
                    :messages="filteredMessages"
                    :change-filter="changeFilter"
                    :show-deleted="showDeleted"
                    :toggle-show-deleted="messages.length ? toggleShowDeleted : null"
                />
            </div>
        </div>

        <div v-if="isLocalDev" class="row">
            <div class="col-xl-5">
                <div class="kt-portlet kt-portlet--height-fluid">
                    <ChatGroups />
                </div>
            </div>

            <div class="col-xl-7">
                <Chat />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Types from '../store/Types';
import globalMixins from '../store/mixins/globalMixins';
import userMixins from '../store/mixins/userMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import RecentMessages from '../components/Communication/RecentMessages.vue';
import MessageInbox from '../components/Communication/MessageInbox.vue';
import ChatGroups from '../components/Communication/ChatGroups.vue';
import Chat from '../components/Communication/Chat.vue';

export default {
    name: 'Communication',
    components: {
        RecentMessages,
        MessageInbox,
        ChatGroups,
        Chat,
    },
    mixins: [globalMixins, userMixins, teacherMixins],
    data() {
        return {
            key: 0,
            filter: '',
            showDeleted: false,
            saving: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        teachers() {
            return this.$_teacherMixins_getTeachers();
        },
        messages() {
            return this.$store.state.database.messages;
        },
        formattedMessages() {
            const { messages } = this;
            return messages
                .map((m) => {
                    const message = { ...m };
                    message.lastMessageAt = moment(message.recentlyActive || message.created).format('MMM Do, YYYY');
                    message.search = `${message.messageSubject || ''} ${message.messageBody || ''} ${message.lastMessageAt || ''}`.toLowerCase();
                    return message;
                }).sort((a, b) => moment(b.recentlyActive || b.created).diff(a.recentlyActive || a.created));
        },
        filteredMessages() {
            const { formattedMessages, filter, showDeleted } = this;
            return formattedMessages.filter((m) => {
                const { deleted, search } = m;
                if ((deleted && !showDeleted) || (!deleted && showDeleted)) return false;
                if (!filter) return true;
                if ('announcement'.includes(filter.toLowerCase()) && m.isSchoolAnnouncement) return true;
                return search.includes(filter.toLowerCase());
            }).sort((a, b) => moment(b.recentlyActive || b.created).diff(a.recentlyActive || a.created));
        },
    },
    watch: {
        formattedMessages() {
            this.key += 1;
        },
    },
    mounted() {
    },
    methods: {
        changeFilter(filter) {
            this.filter = filter;
        },
        toggleShowDeleted() {
            this.showDeleted = !this.showDeleted;
        },
        toggleCreateMessage() {
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
        },
    },
};
</script>
